import { Typography, Grid, Button } from "@mui/material";
import "./wave.css";
function Banner() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="stretch"
      sx={{ flexDirection: "column" }}
      padding={1}
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
    >
      <br />
      <Grid item xs={12}>
        <Typography variant="h2" align="left">
          <b>Hey, Ting Wei here!</b> <span class="wave">👋</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="left" color="grey">
          AI Engineer from Singapore
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" align="left" color="grey">
          Focus on Backend and Data Engineering
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" size="small">
          Resume
        </Button>
        <span> </span>
        <Button variant="contained" size="small">
          Contact Me
        </Button>
      </Grid>
    </Grid>
  );
}

export default Banner;
