import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Divider, responsiveFontSizes, Grid } from "@mui/material";

import { useState } from "react";

import NavBar from "./components/NavBar";
import Banner from "./components/Banner";
import TechStack from "./components/TechStack";

function App() {
  const [darkMode, setDarkMode] = useState(true);
  let theme = createTheme({
    spacing: 8,
    palette: {
      mode: darkMode ? "dark" : "light",
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavBar darkMode={darkMode} setDarkMode={setDarkMode} />
      <Grid container spacing={2}>
        <Grid item xs={1} sm={1.5} md={1.5} lg={1.5} xl={2} />
        <Grid item xs={10} sm={9} md={9} lg={9} xl={8}>
          <Banner />
          <br />
          <Divider />
          <TechStack />
        </Grid>
        <Grid item xs={1} sm={1.5} md={1.5} lg={1.5} xl={2} />
      </Grid>
    </ThemeProvider>
  );
}

export default App;
