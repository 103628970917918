import { Typography, Grid, Button } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import { useState } from "react";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function TechStack() {
  const toolsItems = {
    Backend: ["Django", "Flask", "FastAPI", "Golang", "Python"],
    Frontend: ["React"],
    "Data Engineering": ["Airflow", "Spark", "PostgreSQL"],
    DevOps: ["Docker", "Kubernetes", "AWS"],
  };

  const [currentCategory, setCurrentCategory] = useState("Backend");

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        padding={1}
        rowSpacing={2}
        columnSpacing={2}
        sx={{ flexDirection: "column" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12}>
          <Typography variant="h3" align="left">
            <b>Tech Stack</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="left" color="grey">
            A list of familiar tools and technologies that I use regularly
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {Object.keys(toolsItems).map((category) => (
            <>
              <Button
                key={category}
                variant={
                  currentCategory === category ? "contained" : "outlined"
                }
                onClick={() => setCurrentCategory(category)}
                size="medium"
              >
                {category}
              </Button>
              <span> </span>
            </>
          ))}
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="left"
        alignItems="stretch"
        padding={1}
        rowSpacing={2}
        columnSpacing={2}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {toolsItems[currentCategory].map((tool, index) => (
          <Grid item xs={2} sm={2} md={2} key={index}>
            <Item>{tool}</Item>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
export default TechStack;
